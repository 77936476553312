<template>
    <div class="main" :class="{loading: loading}">
        <filters :scrolled="isScrolled" :is-open="isOpen" @show="showAllParams" />
        <first v-if="!isMobile" />

        <div class="content" v-if="!isMobile">
            <div class="main__list">
                <categories :scrolled="isScrolled" />
                <div class="content">
                    <div class="content__980">
                        <homes-list :homes="homes"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile__content" v-else>
            <homes-list :homes="homes"/>
        </div>
    </div>
</template>

<script>
import First from '@/components/First'
import Filters from '@/components/Filters'
import Categories from '@/components/Categories'
import HomesList from '@/components/HomesList'

export default {
    name: 'HomePage',
    data() {
        return { 
            isScrolled: false,
            isOpen: false,
        }
    },
    watch: { 
        '$route': {
            handler() {
                this.parseQuery()
                this.fetchHomes({limit: true, map: false});
                this.fetchFavs();
            },
            deep: true,
            immediate: true
        },
    },
    components: {
        First, Categories, HomesList, Filters
    },
    mounted() {
        window.document.onscroll = () => {
            if (window.scrollY > 370) {
                this.isScrolled = true;
                return
            }
            this.isScrolled = false;
        }
    },
    methods: {
        showAllParams() {
            this.isOpen = !this.isOpen;
        },
    }
}
</script>

<style lang="scss" scoped>
.main {
    min-height: 100vh;
    &__box {
        margin-bottom: 50px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__title { 
        font: 24px proxima-bold;
        line-height: 1em;
        letter-spacing: 0.01em;
        margin-bottom: 5px;
    }
    &__subtitle { 
        font: 14px proxima-regular;
        letter-spacing: 0.01em;
        color: rgb(180, 180, 180);
        margin-bottom: 15px;
    }
    &__list {
        margin-top: 10px;
        display: flex;
    }
}
.loading {
    min-height: 2500px;
}
</style>