<template>
    <div class="search" id="search">

        <!-- Форма с параметрами -->
        <form @submit.prevent="paramsSubmit" id="params_form" class="form" v-if="isParams">
            
            <!--Категории-->
            <div>
                <div class="form__title">Категория</div>
                <div class="check-group-b">
                    <div class="check">
                        <input type="checkbox" value="3" id="buy" v-model="filter.type" />
                        <label for="buy">Куплю</label>
                    </div>
                    <div>
                        <input type="checkbox" value="1" id="sell" v-model="filter.type"/>
                        <label for="sell">Продам</label>
                    </div>
                    <div>
                        <input type="checkbox" value="4" id="lease" v-model="filter.type"/>
                        <label for="lease">Сниму</label>
                    </div>
                    <div>
                        <input type="checkbox" value="2" id="rent" v-model="filter.type"/>
                        <label for="rent">Сдам</label>
                    </div>
                </div>
            </div>

            <!--Цена-->
            <div>
                <div class="form__title">Цена</div>
                <div class="from-to-group-b space">
                    <div>
                        <input type="text" autocomplete="off" v-model="filter.price1" placeholder="Цена от"/>
                        <input type="text" autocomplete="off" v-model="filter.price2" placeholder="до"/>
                    </div>
                </div>
            </div>

            
            <!--Комнаты-->
            <div>
                <div class="form__title">Комнат</div>
                <div class="check-group-b">
                    <div>
                        <input type="checkbox" value="-1" id="studio_b" v-model="filter.rooms" />
                        <label for="studio_b">Студии</label>
                    </div>
                    <div>
                        <input type="checkbox" value="1" id="one_b" v-model="filter.rooms" />
                        <label for="one_b">1</label>
                    </div>
                    <div>
                        <input type="checkbox" value="2" id="two_b" v-model="filter.rooms" />
                        <label for="two_b">2</label>
                    </div>
                    <div>
                        <input type="checkbox" value="3" id="three_b" v-model="filter.rooms" />
                        <label for="three_b">3</label>
                    </div>
                    <div>
                        <input type="checkbox" value="4" id="four_b" v-model="filter.rooms" />
                        <label for="four_b">4+</label>
                    </div>
                </div>
            </div>

            <button type="submit" class="button-reset" @click.prevent="resetFilters">
                <span class="button__text">СБРОСИТЬ</span>
            </button>

            <button type="submit" class="button">
                <span class="button__text">НАЙТИ</span>
            </button>
        </form>

        <!-- Форма поиска по названию -->
        <form @submit.prevent="paramsSubmit" id="name_form" class="form" v-if="isName">
            <div class="text-input-b">
                <div class="form__title">Название</div>
                <div>
                    <input type="text"
                           v-model="filter.title"
                           placeholder="Название"
                           autocomplete="off"
                    />
                </div>
            </div>
            <button type="submit" class="button-reset" @click.prevent="resetFilters">
                <span class="button__text">СБРОСИТЬ</span>
            </button>
            <button type="submit" class="button">
                <span class="button__text">НАЙТИ</span>
            </button>
        </form>

        <!-- Нижняя полоска с кнопками параметров -->
        <div class="form">
            <div class="form__params">

                <div class="form__mini-button"
                     v-if="isParams"
                     @click="showNameForm"
                >Поиск по названию</div>

                <div class="form__mini-button"
                     v-if="isName"
                     @click="showParamsForm"
                >Поиск по параметрам</div>

                <div class="form__mini-button" @click="openModal">Все параметры</div>
            </div>
        </div>

        <!-- Всплывающее окно с параметрами -->
        <modal v-if="isModal" @close_modal="closeModal" @submit="paramsSubmit" />

        <!-- Кнопка "Показать на карте" -->
        <div class="map__button__container">
            <div class="map__button" @click="mapSubmit">ПОКАЗАТЬ НА КАРТЕ</div>
        </div>

    </div>
</template>

<script>
    import Modal from '@/components/Modal'

    export default {
        components: {
            Modal,
        },
        data() {
            return {
                isParams: true,
                isName: false,
                isModal: false,
            }
        },
        methods: {
            showNameForm() {
                this.isParams = false;
                this.isName = true;
            },
            showParamsForm() {
                this.isParams = true;
                this.isName = false;
            },
            openModal() {
                document.querySelector('html').style.overflowY = 'hidden';
                this.isModal = true;
            },
            closeModal() {
                document.querySelector('html').style.overflowY = 'scroll';
                this.isModal = false;
            },
        }
    }
</script>

<style scoped>
    .map__button__container {
        margin-top: 50px;
        width: 100%;
        display: flex;
    }
    .map__button {
        position: relative;
        margin: 0 auto;
        padding: 0 25px;
        height: 38px;
        border-radius: 38px;
        background: rgb(255, 219, 77);
        cursor: pointer;
        font: 13px proxima-bold;
        text-align: center;
        line-height: 41px;
        letter-spacing: 0.1em;
        color: black;
    }
    .map__button:hover {
        background: rgb(255, 226, 111);
    }

    .search {
        max-width: 1200px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
    }
    .form {
        display: flex;
        margin: 0 auto;
        width: 1200px;
    }
    .form__params {
        display: flex;
        flex: 1 1 0;
        max-width: 885px;
    }
    .form__mini-button {
        margin-top: 12px;
        font: 13px proxima-regular;
        letter-spacing: 0.01em;
        cursor: pointer;
        color: rgb(220, 220, 220);
    }
    .form__mini-button:last-of-type {
        margin-left: auto;
    }
    .form__mini-button:hover {
        color: rgb(247, 192, 11);
    }

    .form__title {
        font: 13px proxima-regular;
        letter-spacing: 0.01em;
        color: rgb(220, 220, 220);
        margin-bottom: 5px;
    }

    .check-group-b {
        display: flex;
        cursor: pointer !important;
        margin-right: 20px;
    }
    .check-group-b div:first-of-type label {
        border-radius: 4px 0 0 4px;
    }
    .check-group-b div:last-of-type label {
        border-radius: 0 4px 4px 0;
    }
    .check-group-b input {
        position: absolute;
        z-index: -50;
    }
    .check-group-b label {
        background: white;
        display: block;
        margin: 0 0 0 1px;
        cursor: pointer;
        font: 13px proxima-regular;
        height: 40px;
        padding: 0 22px;
        line-height: 40px;
    }
    .check-group-b input:checked + label {
        position: relative;
        background: rgba(255, 235, 160, 1);
        z-index: 2;
    }
    .check-group-b input:checked + label:hover {
        background: rgba(255, 235, 160, 1);
    }
    .check-group-b input + label:hover {
        background: rgba(255, 247, 215, 1);
        position: relative;
        z-index: 1;
    }

    .from-to-group-b div {
        display: flex;
        margin-right: 20px;
    }
    .from-to-group-b div input:first-of-type {
        border-radius: 4px 0 0 4px;
    }
    .from-to-group-b div input:last-of-type {
        border-radius: 0 4px 4px 0;
    }
    .from-to-group-b input {
        border: 0;
        position: relative;
        margin: 0 0 0 1px;
        height: 40px;
        padding: 0;
        font: 13px proxima-regular;
        line-height: 40px;
        text-indent: 15px;
        width: 105px;
    }
    .from-to-group-b input:hover {
        z-index: 1;
    }
    .from-to-group-b input:focus {
        z-index: 2;
    }

    .text-input-b div {
        display: flex;
        margin-right: 20px;
    }
    .text-input-b div input {
        border: 0;
        margin: 0 0 0 1px;
        height: 40px;
        width: 885px;
        padding: 0;
        border-radius: 4px;
        font: 13px proxima-regular;
        line-height: 41px;
        text-indent: 15px;
    }

    .button {
        margin-top: 21px;
        margin-left: 17px;
        border: 0;
        height: 40px;
        padding: 0 30px;
        border-radius: 60px;
        background: rgba(255, 219, 77, 1);
        cursor: pointer;

    }
    .button-reset {
        margin-top: 21px;
        margin-left: 17px;
        border: 0;
        height: 40px;
        padding: 0 30px;
        border-radius: 60px;
        cursor: pointer;
        color: white;
        background: #445f7a;

    }
    .button-reset:hover {
        background: #587897;

    }
    .button:hover {
        background: rgba(255, 226, 111, 1);
    }
    .button__text {
        font: 13px proxima-bold;
        line-height: 42px;
        letter-spacing: 0.1em;
    }
</style>