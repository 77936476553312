<template>
    <div class="first">

        <div class="first__content">
            <div class="first__title">Агрегатор недвижимости</div>

            <Search />
        </div>

        <div class="first__img-container">
            <div class="mp-first__video-blur"></div>
            <div class="mp-first__video"></div>
        </div>
    </div>
</template>

<script>
import Search from '@/components/Search'

export default {
    components: {
        Search,
    },
}
</script>

<style lang="scss" scoped>
.first {
    width: 100vw;
    height: 450px;
    margin-top: 50px;
    margin-bottom: 10px;
    &__content {
        position: relative;
        margin: 0 auto;
        padding: 0 20px;
        height: 450px;
        z-index: 3;
    }
    &__title {
        max-width: 100%;
        padding: 80px 0 80px 0;
        font: 35px proxima-bold;
        color: #fff;
        letter-spacing: -0.01em;
        text-align: center;
    }
    &__img-container {
        position: relative;
        top: -450px;
        margin: 0 auto;
        height: 450px;
        overflow: hidden;
    }
}
.mp-first__video {
    padding: 20px;
    width: 100%;
    height: 450px;
    background: url('../assets/main_resize.jpg');
    background-position: center center;
    -webkit-filter: blur(20px);
    filter: blur(15px);
    &-blur {
        position: absolute;
        left: 0; right: 0;
        max-width: 100%;
        height: 450px;
        background: #637b96;
        opacity: .7;
        z-index: 1;
    }
}
</style>