<template>
    <div class="paginator">

        <div
             v-for="(button, id) of paginate" :key="id"
             v-on:click="switchPage(button.page_to)"
             v-bind:class="{paginator__item: button.type === 'number', paginator__item_active: button.active === true, paginator__row: button.type === 'text', paginator__dot: button.type === 'dot', paginator__dot_hidden: button.show === false}"
        >
            {{ button.title }}
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            paginate: []
        }
    },
    computed: mapGetters(['pages']),
    mounted() {
        this.getPaginate()
    },
    watch: {
        pages: function() {
            this.getPaginate()
        }
    },
    methods: {
        getPaginate() {
            // Скрываем пагинатор, если всего одна страница
            if (this.pages.all <= 1) {
                this.paginate = [];
                return null
            }

            // Определяем активную страницу при загрузке
            let active_page = this.pages.current

            // Вычисляем максимальнную и минимальную страницу для отображения
            let extra_pages = 2;
            let min_page = active_page - extra_pages;
            let max_page = active_page + extra_pages * 2;
            if (min_page <= 0) {
                min_page = 1;
                max_page = 7;
            }
            if (max_page >= this.pages.all) {
                max_page = this.pages.all;
                min_page = this.pages.all - 6;
            }

            // Создаем массив с кнопками пагинации
            let pages = [{
                title: this.isMobile ? 'Начало' : 'В начало', page_to: 1, active: false, show: false, type: 'text',
            }];

            for (let i = 1; i <= this.pages.all; i++) {
                if (min_page <= i && i <= max_page) {
                    pages.push({
                        title: i, page_to: i, active: false, show: true, type: 'number',
                    })
                }
            }
            if (this.pages.all > 7) {
                pages[7] = {
                    title: this.pages.all, page_to: this.pages.all, active: false, type: 'number',
                };
                if (this.pages.all - 2 != pages[5].title) {
                    pages[6] = {
                        title: '...', page_to: null, active: false, show: true, type: 'dot',
                    }
                }
                if (active_page > 3) {
                    pages[0].show = true;
                }
            }

            // Устанавливаем стиль для активной кнопки пагинации
            for (let p of pages) {
                if (p.title === active_page) {
                    p.active = true;
                }
            }

            this.paginate = pages;
        },
        switchPage(page_number) {
            if (page_number === null) {
                return null;
            }

            // проставить всем кнопкам false
            for (let p of this.paginate) {
                p.active = false;
            }

            // поставить true на этот page_number
            for (let page of this.paginate) {
                if (page.title === page_number) {
                    page.active = true;
                }
            }

            // очистить url от значений "page" и добавить новое
            this.$router.replace({ query: { ...this.formQuery(), page: page_number } })

            // скроллим страницу наверх
            if (this.isMobile) window.scrollTo({ top: 200, behavior: 'smooth' });
            if (!this.isMobile) window.scrollTo({ top: 372, behavior: 'smooth' });

            // перерисовать кнопки пагинации
            this.getPaginate()
        },
    }
}
</script>

<style scoped>
.paginator {
    margin: 50px 0;
    display: flex;
}
.paginator__item {
    margin: 0 5px;
    width: 40px;
    height: 40px;
    border-radius: 60px;
    cursor: pointer;
    font: 15px proxima-bold;
    /*color: #000;*/
    color: rgb(76, 100, 128);
    text-align: center;
    line-height: 40px;
}
.paginator__item:hover {
    background: rgba(224, 240, 255, 1);
}

.paginator__item_active {
    background: rgba(255, 219, 77, 1);
    cursor: default;
}

.paginator__item_active:hover {
    background: rgba(255, 219, 77, 1);
}
.paginator__dot {
    margin: 0 5px;
    width: 40px;
    height: 40px;
    border-radius: 60px;
    font: 15px proxima-bold;
    /*color: #000;*/
    color: rgb(76, 100, 128);
    text-align: center;
    line-height: 40px;
}
.paginator__dot_hidden {
    display: none;
}

.paginator__row {
    font: 15px proxima-bold;
    letter-spacing: 0.01em;
    margin: 0 5px;
    line-height: 40px;
    color: rgb(76, 100, 128);
}
.paginator__row:hover {
    color: rgba(250, 82, 50, 1);
    cursor: pointer;
}
</style>
