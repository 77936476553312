<template>
    <div id="homes_list" class="homes_wrapper">
        <div :class="[!isMobile ? 'homes__list' : 'mobile-homes__list']">
            <Tags v-if="isMobile" />
            <SortOrder />

            <preloader v-if='loading' :class="[!isMobile ? 'main__preloader' : 'mobile-main__preloader']" />
            <h1 v-else-if="homes.length === 0">Результаты не найдены</h1>
            <Home v-else
                    v-for="(home, id) of homes" 
                    :key="id"
                    :home="home"
                    :home_id="home.id"
                    class="home"
                    @click="goToHome(home.id)"
            />

            <Paginator v-if="!loading" :pages_count="1" />
        </div>
    </div>
</template>

<script>
import Home from '@/components/Home'
import SortOrder from '@/components/SortOrder'
import Paginator from '@/components/Paginator'
import Preloader from '@/components/Preloader'
import Tags from '@/components/Tags'

export default {
    components: {
        Home, SortOrder, Paginator, Preloader, Tags
    },
    methods: {
        goToHome(id) {
            this.$router.push({ name: 'home', params: { id }})
        }, 
    }
}
</script>

<style>
.mobile-homes__list {
    margin-top: 0px;
}
.homes_wrapper {
    width: 100%;
}
.homes__list {
    position: absolute;
    left: calc((100vw - 1200px) / 2 + 235px);
}
.home:hover {
    cursor:  pointer !important;
}
.main__preloader {
    margin: calc(50vh / 2) 12vw;
}
.mobile-main__preloader {
    margin: calc(65vh / 2) auto;
}
</style>