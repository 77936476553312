<template>
    <div class="sort" id="sort">
        <div class="sort__title text-s-bold">Сортировка:</div>
        <div class="sort__item text-s link"
             v-for="sort in sorts" :key="sort.id"
             :class="{sort__item_active: activeSorts.includes(sort.id)}"
             @click="sortHomes(sort.id)"
        >
            {{sort.title}}
            <div 
                class="sort__arrow" 
                :class="[activeSorts.includes(sort.id) ? 'sort__arrow_active' : '', activeSorts.includes(sort.id) && filter.sort % 2 != 0 ? 'sort__arrow-rotated' : '']"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="40" viewBox="0 0 365 512">
                    <path d="M251.3,124.7l-56-56c-6.2-6.2-16.4-6.2-22.6,0l-56,56c-6.2,6.2-6.2,16.4,0,22.6c6.2,6.2,16.4,6.2,22.6,0l28.7-28.7V432  c0,8.8,7.2,16,16,16s16-7.2,16-16V118.6l28.7,28.7c3.1,3.1,7.2,4.7,11.3,4.7s8.2-1.6,11.3-4.7C257.6,141.1,257.6,130.9,251.3,124.7  L251.3,124.7z"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sorts: [
                {id: -2, title: 'дата'},
                {id: 2, title: 'цена'},
            ]
        }
    },
    computed: {
        activeSorts() {
            return [Number(this.filter.sort) - 1, Number(this.filter.sort), Number(this.filter.sort) + 1]
        }
    },
    methods: {
        sortHomes(id) {
            if (Math.sign(Number(this.filter.sort)) === Math.sign(id))  {
                if (Number(this.filter.sort) === id) {
                    this.filter.sort = `${Number(this.filter.sort) + 1}`
                    this.paramsSubmit()
                    return;
                }
                this.filter.sort = `${Number(this.filter.sort) - 1}`
                this.paramsSubmit()
                return;
            }
            this.filter.sort = id
            this.paramsSubmit()
        },
    }
}
</script>

<style lang="scss" scoped>
.sort {
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    &__title {
        margin-right: 5px;
        color: rgb(36, 89, 124);
    }
    &__arrow {
        fill: rgb(162, 162, 163);
        display: flex;
        align-items: center;
        width: 14px;
        height: 40px; 
        transition: .4s ease-in-out;
        &-rotated {
            transform: rotate(180deg);
        }
        &_active {
            cursor: pointer;
            fill: rgb(36, 89, 124);
        }
    }
    &__item {
        margin: 0 7px;
        display: flex;
        align-items: center;
        color: rgb(162, 162, 163);
        &:hover {
            cursor: pointer;
            color: rgb(36, 89, 124);
        }
        &_active {
            cursor: pointer;
            color: rgb(36, 89, 124);
        }
    }
}
</style>